/*NOTE: This will force the Sisense widget to the right, but only if the browser window is 1500px or larger*/
@media screen and (min-width: 1500px) {
  .forceToRightForLargeWindow {
    position: absolute;
    right: 0;
  }
}

.forceIconToRight {
  float: right;
  /* text-align:right; */
  /* margin-left: auto; 
margin-right: 0; */
}

.negativeCurrency {
  color: red;
}

td.noWrap {
  white-space: nowrap;
}

.k-grid td.noWrap {
  white-space: nowrap;
}

.k-button {
  margin: 5px;
}
