/* .reducedHeight .k-button-text {
    font-size: 0.8em;
} */

.noPadding{
    padding: 0px !important;
}

.noPadding i {
    font-size: 0.8em;
}

.noPadding .k-button {
    padding-left: 0px;
    padding-right: 0px;
}

.noPadding .k-dropdown-button {
    padding-top: 4px;
    padding-bottom: 4px;
}

.reducedHeight .k-dropdown-button {
    height: 25px;
}

.reducedHeight .k-dropdown-button button {
    padding-top: 0px;
    padding-bottom: 0px;
}
