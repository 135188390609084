.selectable-row-cell {
    font-size: 14px;
    color: #4A4A4A;
    font: sans-serif;
    display: table-cell;
    vertical-align: inherit;
    white-space: nowrap;
    z-index: 0;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.selectable-row-cell-input{
    vertical-align: middle;
}