.multi-select-dropdown-option-label {
    padding-left: 5px;
}

.multi-select-dropdown-option {
    padding: 8px;
    background-color: #FFF;
    border-color: #CCC;
    border: 0px;
    font-size: 14px;
    color: black;
}


.multi-select-dropdown-option.selected {
    padding: 8px;
    background-color: #015cab;
    border-color: #CCC;
    border: 0px;
    font-size: 14px;
    color: white;
}

    .multi-select-dropdown-option.disabled {
        padding: 8px;
        background-color: gray;
        border-color: #CCC;
        border: 0px;
        font-size: 14px;
        color: white;
    }