.multi-select-dropdown-label {
    padding-right: 5px;
}

.multi-select-dropdown {
    padding: 8px;
    background-color: #FFF;
    border-color: #CCC;
    border: 2px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .08);
    font-size: 14px;
    width: 100%;
}

.multi-select-dropdown.expanded {
    padding: 8px;
    border: 2px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .08);
    font-size: 14px;
}

.selection-highlight {    
    background-color: #015cab;
    border: 0;
    color: #fff;
    font-size: 14px;
    padding: 8px;
  }