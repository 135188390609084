.total-footer {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #034681;
    color: #FFFFFF;
}

.grand-total-footer-text {
    font-size: 16px;
    font-family: sans-serif;
}

.money-cell {
    text-align: right;
}

.number-cell {
    text-align: right
}

.negative {
    color: red;
}

.negative::before {
    content: '(';
}

.negative::after {
    content: ')';
}

.total-footer-row {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.total-footer-cell {
    font-size: 14px;
    white-space: nowrap;
    z-index: 0;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: inherit;
}

.total-footer-cell pre {
    display: inline;    
}