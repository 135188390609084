.data-table-extensions-wrapper {
    
}
.data-table-wrapper {
    overflow-x: auto;
}

.data-table {
    width: 100%;
    table-layout: auto; 
    border-collapse: collapse;
    display: table;
}

.data-table-head {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.data-table-body {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}