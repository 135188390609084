.k-grid-header .k-table-th.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-row-sticky,
.k-grid .k-grid-footer-sticky {
  z-index: 1;
}

/* Maybe consider just changing all "default-grid" instances to "tight-grid multi-line-filter"? */

.default-grid .k-table {
  font-size: 1em;
}

.default-grid .k-spin-button .k-button {
  margin: 0px;
}

.tight-grid .k-table {
  font-size: 0.8em;
}

.tight-grid .k-spin-button .k-button {
  margin: 0px;
}

.tight-grid .k-input-inner {
  font-size: 0.8em;
}

.tight-grid .k-button {
  font-size: 0.8em;
}

.tight-grid .k-input-button {
  margin: 0px;
}

/* NOTE: Okay so here we're modifying the size of the header buttons,
but it's sort of inconsequential.
There are differences but it doesn't end up freeing up much of the page.
*/
/*
.tight-grid .k-button {
  margin: 2px;
}

.tight-grid .k-icon-button {
  padding: 0.075em;
}

.tight-grid .k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
  height: 10px;
  width: 10px;
} */

.k-table .k-column-title {
  padding-bottom: 2px;
}

.k-table .k-filtercell-wrapper {
  /* display: inline-grid !important; */
  max-width: 15em;
}

.multi-line-filter .k-table .k-filtercell-wrapper {
  display: inline-grid !important;
  max-width: 15em;
}
