/* NOTE: Any CSS that's applied locally here in a component still ends up getting added globally.
Meaning the style doesn't end up getting restricted to just the "component importing the stylesheet".
For example, I'm using .hyperlinkStyle in the TutorialVideo.js file
even though I'm not explicitly importing the adminRemittance.css file.
There are means of restricting a CSS file locally to a component, it essentially ends up
being a library that modifies the class so that it's ".hyperlinkStyle-<random string specific to the component>".
*/

.hyperlinkStyle{
    text-decoration:underline; 
    color:blue; 
    cursor:pointer
}

.applyRedColor{
    color:red;
}