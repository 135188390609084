.navbar-brand {
  padding: 0px;
}

/* Left Accordion css*/
.sub-menu ul {
  list-style: none;
  padding-left: 0px;
  transition: all 0.5s ease;
  line-height: 40px;
}

@keyframes fade {
  from {
    height: 0;
    visibility: hidden;
    transform: translateY(-25px);
  }

  to {
    height: auto;
    visibility: visible;
    transform: translateY(0);
  }
}

.sub-menu {
  animation: fade 0.05s linear forwards;
}

.sub-menu ul li {
  margin-left: 60px;
  text-align: left;
}

.sub-menu ul li a {
  color: #f5f5f5;
}

.accordion-section-wrapper ul {
  list-style: none;
  padding-left: 0px;
}

.accordion-section-wrapper ul li {
  margin-left: 60px;
  text-align: left;
}

.accordion-section-wrapper ul li a {
  color: #f5f5f5;
  text-decoration: none;
  font-size: 14px;
}

.accordion-section-collapsed {
  width: 40px;
  margin: 10px;
}

.accordion-section-expanded {
  width: 325px;
  margin: 5px;
}

.accordion-section-wrapper {
  width: 380px;
}

.accordion-section-selected {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #f5f5f5;
}

.accordion-section-wrapper i {
  font-family: FontAwesome;
  font-size: 20px;
  position: relative;
  display: table-cell;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  margin-right: 5px;
}

.accordion-wrapper {
  background-color: #015cab;
}

#accordion {
  background-color: #015cab;
  color: #f5f5f5;
  text-align: center;
  line-height: 40px;
  transition: 0.3s;
  overflow: hidden;
  z-index: 1;
  overflow-y: auto;
}

.accordion-expanded {
  width: 325px;
  overflow-y: scroll;
}

.accordion-collapsed {
  width: 50px;
}

#accordion::-webkit-scrollbar {
  display: none;
}

.accordion-label {
  position: relative;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 240px;
  padding-left: 5px;
  color: #f5f5f5;
  font-size: 14px;
}

.accordion-section-wrapper a:hover {
  color: #f5f5f5;
  text-decoration: underline;
}

.accordion-section-wrapper a {
  color: #f5f5f5;
}

.accordion-section-arrow {
  position: relative;
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  margin-right: 15px;
  width: 40px;
}

#content-search {
  text-align: right;
}

.additional-info-popover-icon {
  color: #015cab;
  font-size: 14px;
  margin-left: 20px;
}

.header-info-popover-icon {
  color: #015cab;
  font-size: 14px;
  margin-left: 5px;
}

.feature > h1 {
  display: inline-block;
}

#content {
  height: 100vh;
  width: 100%;
}

.flex-fill {
  flex: 1;
}

#main-content {
  background-color: #f5f5f5;
  height: calc(100% - 40px);
}

.feature-tabs {
  background-color: #e3e3e3;
  padding-left: 30px;
  padding-top: 30px;
}

.feature {
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.feature-tabs .nav-link {
  color: #034681;
  background-color: #bbbbbb;
}

.nav-tabs .nav-link.active {
  color: #034681;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.feature-tab {
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.tab-feature-wrapper {
  width: 100%;
  padding-bottom: 50px;
}

.tab-feature-wrapper .tab-content {
  height: calc(100% - 72px);
}

.feature-table thead {
  background-color: #ebebeb;
  color: #015cab;
}

#footer i {
  font-family: FontAwesome;
  font-size: 14px;
  margin-right: 5px;
}

.action-link-with-icon {
  margin-right: 10px;
  margin-left: 10px;
}

.action-link-with-icon a {
  color: white;
}

#footer {
  background-color: #034681;
  padding: 0px 30px 0px 30px;
  vertical-align: middle;
  position: fixed;
  bottom: 0;
  line-height: 40px;
  font-family: sans-serif;
  font-size: 14px;
  color: #f5f5f5;
  z-index: 10;
}

#copyright {
  margin-right: 60px;
  text-align: right;
}

.react-datepicker-wrapper {
  width: 120px;
}

.react-datepicker__input-container input {
  width: 120px;
}

div.react-datepicker-popper {
  z-index: 5;
}

/* td,
th {
  white-space: nowrap;
  z-index: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

th {
  font-size: 12px;
  color: #015cab;
  font-family: sans-serif;
  background-color: #ebebeb;
} */

.filter-header {
  background-color: white;
}

.filter-header span .form-control {
  display: inline;
  border: 0;
}

.primary-button {
  height: 40px;
  background-color: rgba(1, 92, 171, 1);
  color: #f5f5f5;
  font-size: 14px;
}

.form-check {
  padding-left: 20px;
  padding-right: 35px;
}

.inline-form-check {
  padding-top: 32px;
}

.inline-show-result-button {
  margin-top: 32px;
  height: 38px;
  background-color: #015cab;
}

.inline-show-result-icon {
  margin-top: 32px;
  margin-left: -12px;
}

tr .bool-type {
  font-family: FontAwesome;
  color: #015cab;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

.grid-details-modal .modal-dialog {
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.grid-remittance-history-modal .modal-dialog {
  max-width: 80%;
  max-height: 50%;
}

.grid-claim-ignore-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.filter-type-list {
  list-style-type: none;
  padding: 0;
  text-align: center;
  font-size: 20px;
}

#login-form .form-row .form-group {
  width: 90%;
}

#password-input {
  border-right: 0;
}

#show-password-icon {
  font-family: FontAwesome;
  font-size: 20px;
  color: #015cab;
}

#login-title {
  font-size: 40px;
  text-align: center;
}

/* Aging-details fixed columns */
#Aging-details-table thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  background: #ebebeb;
}

#Aging-details-table thead tr:nth-child(2) th:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  background: #ffffff;
}

#Aging-details-table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  background: #f5f5f5;
}

#Aging-details-table tfoot td:first-child {
  position: sticky;
  left: 0;
  z-index: 0;
  background: #034681;
}

#Aging-details-table thead tr:first-child :nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 0;
  background: #ebebeb;
}

#Aging-details-table thead tr:nth-child(2) th:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 0;
  background: #ffffff;
}

#Aging-details-table tbody td:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 0;
  background: #f5f5f5;
}

#Aging-details-table tfoot td:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 0;
  background: #034681;
}

#Aging-details-table thead tr:first-child :nth-child(3) {
  position: sticky;
  left: 200px;
  z-index: 0;
  background: #ebebeb;
}

#Aging-details-table thead tr:nth-child(2) th:nth-child(3) {
  position: sticky;
  left: 200px;
  z-index: 0;
  background: #ffffff;
}

#Aging-details-table tbody td:nth-child(3) {
  position: sticky;
  left: 200px;
  z-index: 0;
  background: #f5f5f5;
}

#Aging-details-table tfoot td:nth-child(3) {
  position: sticky;
  left: 200px;
  z-index: 0;
  background: #034681;
}

#Aging-details-table thead tr:first-child :nth-child(4) {
  position: sticky;
  left: 300px;
  z-index: 0;
  background: #ebebeb;
}

#Aging-details-table thead tr:nth-child(2) th:nth-child(4) {
  position: sticky;
  left: 300px;
  z-index: 0;
  background: #ffffff;
}

#Aging-details-table tbody td:nth-child(4) {
  position: sticky;
  left: 300px;
  z-index: 0;
  background: #f5f5f5;
}

#Aging-details-table tfoot td:nth-child(4) {
  position: sticky;
  left: 300px;
  z-index: 0;
  background: #034681;
}

#Aging-details-table thead tr:first-child :nth-child(5) {
  position: sticky;
  left: 400px;
  z-index: 0;
  background: #ebebeb;
}

#Aging-details-table thead tr:nth-child(2) th:nth-child(5) {
  position: sticky;
  left: 400px;
  z-index: 0;
  background: #ffffff;
}

#Aging-details-table tbody td:nth-child(5) {
  position: sticky;
  left: 400px;
  z-index: 0;
  background: #f5f5f5;
}

#Aging-details-table tfoot td:nth-child(5) {
  position: sticky;
  left: 400px;
  z-index: 0;
  background: #034681;
}

.popover-body .dropdown-item {
  color: #015cab;
  font-size: 16px;
  font-family: sans-serif;
}

.popover-body i {
  font-size: 14px;
  color: #015cab;
  margin-right: 5px;
}

.feature-sub-heading {
  padding-left: 25px;
}

.dashboard-card-group {
  margin-left: 0px;
  margin-right: 0px;
}

.h-33 {
  height: 33% !important;
}

.w-33 {
  width: 33% !important;
}

.white-space-none {
  white-space: break-spaces;
}

.dashboard-card {
  /* margin: 5px; Normal height */
  margin: 10px;
  background-color: #ffffff;
}

.dashboard-widget-wrapper {
  height: 85%;
}

.dashboard-widget-payment-exception-wrapper {
  height: 85%;
  padding: 0px;
  margin: 0px;
}

.dashboard-widget-wrapper iframe {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sisense-feature {
  padding: 30px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.full-sisense-feature {
  padding: 30px;
  height: 100%;
}

.temp-larger-size {
  height: 800px;
}

.full-sisense-feature iframe {
  border-width: 0;
}

.forgot-password-text {
  color: #007bff;
}

.forgot-password-text:hover {
  cursor: pointer;
}

.dashboard-padding-wrapper {
  height: 100%;
  padding: 20px;
  background-color: #bbbbbb;
}

@media (min-width: 576px) {
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0;
    margin: 5px;
  }
}

.clickableGridEntry {
  color: #015cab;
}

.alert {
  color: black;
}

.newsBody {
  height: 300px;
  overflow: auto;
}

@media (max-width: 575.98px) {
  .newsBody {
    height: 240px;
  }

  .dashboard-widget-wrapper {
    height: 82%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .newsBody {
    height: 240px;
  }

  .dashboard-widget-wrapper {
    height: 80%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .newsBody {
    height: 240px;
  }

  .dashboard-widget-wrapper {
    height: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .newsBody {
    height: 240px;
  }

  .dashboard-widget-wrapper {
    height: 80%;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .newsBody {
    height: 240px;
  }

  .dashboard-widget-wrapper {
    height: 73%;
  }
}

@media (min-width: 1400px) {
  .newsBody {
    height: 300px;
  }

  .dashboard-widget-wrapper {
    height: 84%;
  }
}

.remove-hyperLinkLine {
  text-decoration: none !important;
}

.add-hyperLinkLine {
  text-decoration: underline !important;
}

div.higher-zindex-filters {
  z-index: 10;
}

ul.bullet-info-list {
  list-style-type: disc;
  font-size: 0.85em;
  margin-left: 2px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optional: You might want to visually indicate that the element is disabled */
  background: #dddddd;
}

div.size-to-content {
  display: inline-block;
}

/* Adds approximately 1 line of padding to the bottom of the element. Useful for adding space between elements. */
.spacer_bottom-padding-oneline {
  padding-bottom: 1em;
}

.accordion-pin-toggle-collapsed {
  position: absolute;
  left: 51px;
  top: 160px;
  color: white;
  transition: 0.3s;
}

.accordion-pin-toggle-expanded {
  position: absolute;
  left: 325px;
  top: 160px;
  color: white;
  transition: 0.3s;
}

.accordion-pin-toggle-icon {
  width: 22px;
  height: 22px;
  background-color: #015cab;
  color: #f5f5f5;
  text-align: center;
  vertical-align: bottom;
  border-radius: 24px;
  border: #f5f5f5 2px solid;
  box-shadow: 0 0 0px #f5f5f5;
}

.hidden-element {
  display: none;
}

div.hidden-div {
  display: none;
}

div.visible-div {
  display: block;
  margin: 0; /* No margin around the element */
  padding: 0; /* No padding inside the element */
}