// Compatible with @progress/kendo-theme-bootstrap v.6.4.0

%tb-typography {
  @include typography-classes($tb-typography);
}

%tb-effects {
  @include effects-classes($tb-effects);
}

    .k-button.k-button-solid-primary.k-hover, .k-button.k-button-solid-primary.k-state-hover, .k-button.k-button-solid-primary.k-state-hovered, .k-button.k-button-solid-primary:hover{
        background-color: $tb-kendo-link-hover-text;
                background-image: none;
        
}
    .k-grid.k-grid-md .k-toolbar.k-grid-toolbar.k-toolbar-md{
        padding-bottom: 0px;
                padding-left: 0px;
                padding-right: 0px;
                padding-top: 0px;
                row-gap: unset;
                z-index: 2;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row .k-header.k-table-th.k-focus .k-cell-inner .k-link,.k-grid.k-grid-md .k-table-thead .k-table-row  .k-header.k-table-th.k-state-focus .k-cell-inner .k-link,.k-grid.k-grid-md .k-table-thead .k-table-row  .k-header.k-table-th.k-state-focused .k-cell-inner .k-link,.k-grid.k-grid-md .k-table-thead .k-table-row  .k-header.k-table-th:focus .k-cell-inner .k-link,.k-grid.k-grid-md  .k-grid-header .k-table-row .k-header.k-table-th.k-focus .k-cell-inner .k-link,.k-grid.k-grid-md  .k-grid-header .k-table-row  .k-header.k-table-th.k-state-focus .k-cell-inner .k-link,.k-grid.k-grid-md  .k-grid-header .k-table-row  .k-header.k-table-th.k-state-focused .k-cell-inner .k-link,.k-grid.k-grid-md  .k-grid-header .k-table-row  .k-header.k-table-th:focus .k-cell-inner .k-link{
        flex-direction: row;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row,.k-grid.k-grid-md  .k-grid-header .k-table-row{
                        @extend %tb-typography-GridHeader;
              background-color: $tb-whitesmoke;
                background-image: none;
        
}
    .k-button.k-button-solid-primary.k-hover .k-button-text, .k-button.k-button-solid-primary.k-state-hover .k-button-text, .k-button.k-button-solid-primary.k-state-hovered .k-button-text, .k-button.k-button-solid-primary:hover .k-button-text{
        background-color: initial;
                background-image: none;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row.k-filter-row .k-table-th .k-filtercell,.k-grid.k-grid-md  .k-grid-header .k-table-row.k-filter-row .k-table-th .k-filtercell{
        display: block;
                flex-direction: row;
                justify-content: center;
                box-sizing: content-box;
                overflow: auto;
        
}
    .k-grid.k-grid-md .k-table-thead .k-table-row.k-filter-row,.k-grid.k-grid-md  .k-grid-header .k-table-row.k-filter-row{
                        @extend %tb-typography-kendo-default-typography;
              position: relative;
        
}