div.remittanceSummaryContainer {
  /* background-color: whitesmoke; */
  padding-top: 1em;
  padding-left: 0.5em;

  font-weight: 600;
  min-height: 12em;
  font-size: 1.2em;

  display: flex;
  flex-direction: column;

  /* try to prevent wrapping */
  white-space: nowrap;

  justify-content: space-evenly;
}

div.loadingHomePageRemittancesContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

div.remittancecCountColor {
  color: #1a538a;
}

div.remittancecDollarValueColor {
  color: #53a0f2;
}

div.remittancecEarliestDateColor {
  color: #fe9b44;
}

.loadingHomePageRemittancesContainer .loadingHomePageRemittancesLoader {
  width: 60px;
  aspect-ratio: 6;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
