/*.bordered-control {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    height: 35vmin;
}*/

.bordered-control {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
}

/*.dark-gray-hr {
    color: #6C6A5E;
}*/


.neutral-hr {
    background-color: #BBBBBB;
}

.small-label {
    color: gray;
    font-size: smaller;
}

.form-margin {
    margin-bottom: 10px;
}

/*.bordered-control-left {
    margin-left: 20px;
}

.bordered-control-right {
    margin-right: 20px;
}*/