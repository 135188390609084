#content-header {
  min-height: 40px;
  background-color: #034681;
  padding: 7.5px 30px 7.5px 90px;
  line-height: 40px;
  vertical-align: middle;
}

#feature-title {
  color: #f5f5f5;
  font-size: 40px;
  min-height: 1em;
}
