.accordion-header {
    font-size: 14px;
    font-family: sans-serif;
    color: #015CAB;
    margin-bottom: 10px;
    line-height: 16px;
}

.accordion-header-icon {
    margin-right: 5px;
}

.accordion-header-label {
    display: inline;

}