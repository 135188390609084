#organization-action-link {
    text-align: center;
}

#organization-action-link i {
    margin-right: 5px;
}

#screen-header-action-link-group {
    text-align: right;
}

#screen-header .action-link-with-icon i {
    font-size: 24px;
    margin-right: 5px;
}

.popover-basic{
    padding: 5px 8px;
}

#screen-header {
    min-height: 90px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 5px 30px 5px 30px;
    color: #015CAB;
}

.avatar-wrapper {
    display: inline-block;
}

#user-avatar {
    font-size: 38px;
    margin-right: 5px;
}

#user-avatar-initials {
    color: #F5F5F5;
    font-family: sans-serif;
    position: absolute;
    top: 19px;
    left: 32px;
    transform: translate(-50%, -50%);
}

#username {
    font-size: 14px;
    font-family: sans-serif;
    line-height: 38px;
}
