div.loadingSmallControlSpinnerContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 8em;
  width: 100%;
}

.loadingSmallControlSpinnerContainer .loadingSmallControlSpinnerLoader {
  /* min-height: 200px; */
  width: 60px;
  aspect-ratio: 6;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
