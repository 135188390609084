.cell {
    font-size: 14px;
    color: #4A4A4A;
    font: sans-serif;
    display: table-cell;
    vertical-align: inherit;
    white-space: nowrap;
    z-index: 0;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.money {
    text-align: right;
}

.number {
    text-align: right
}

.negative {
    color: red;
}

    .negative::before {
        content: '(';
    }

    .negative::after {
        content: ')';
    }

.cell.cell--alert {
    color: red;
}

.money.cell--alert {
    color: red;
}

.cell--selectable {
    cursor: pointer;
    color: #007BFF;
}

    .cell--selectable:hover {
        text-decoration: underline;
    }

.cell--truncatable {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 275px;
}

.cell pre {
    display: inline;
}

.datepicker-icon {
    cursor: pointer;
    color: #015CAB;
    float: right;
    border: 0
}

.cell--bool--true {
    color: green;
}

.cell--bool--false {
    color: red;
}