
.multi-select-dropdown-body ul {
    list-style: none;
    padding: 0px;
    max-height: 300px;
    display: block;
}

.multi-select-dropdown-body ul li button {
    width: 100%;
    text-align: left;
}

.multi-select-dropdown-body {
    background-color: white;
    border-radius: 4px;
    margin-top: 8px;
    position: absolute;
    z-index: 6;
    overflow-y: auto;
    max-height: 360px;
    width: max-content;
}


.multi-select-dropdown-body.hidden {
    display: none
}
