.grid-details-modal2 .modal-dialog {
  max-width: 50%;
}

.k-combobox input.k-input-inner {
  white-space: nowrap;
}

.addNewRemittanceTextInfoSpanStyle {
  font-size: 0.8em;
  font-style: italic;
  background-color: whitesmoke;
}
/* disable a section of the upload rendered control */
/* This allows user to delete the file uploaded but we have a custom implementation for that */
.k-upload .k-upload-files {
  display: none;
}
