.fee-and-adjustment-detail-summary-wrapper {
  width: 100%;
  padding-left: 20px;
}

.k-animation-container {
  z-index: 2000 !important; /* Adjust as needed */
}

/* div.k-filtercell-wrapper {
  min-width: 100px;
}

div.k-filtercell span.k-input {
  min-width: 50px;

  color: orange;
} */
