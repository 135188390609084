.k-grid td.missingRemittanceWiderGridColumns {
  /* width: 12em; */
  word-wrap: break-word;
}

.k-grid td.missingRemittanceStatusColumn {
  white-space: nowrap;
  width: 12em;
}

td.centered {
  text-align: center;
}

a.missingRemittancePageLink {
  cursor: pointer;
  text-decoration: underline;
  color: #015cab;
  font-weight: bolder;
}
