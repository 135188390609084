
.selectable-header-cell {
    height: 40px;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: inherit;
    font-size: 12px;
    color: #015CAB;
    font-family: sans-serif;
    border-top: 1px solid rgba(1,92,171, 1);
    border-bottom: 1px solid rgba(1,92,171, 1);
    font-weight: bold;
    background-color: #e3e3e3;
}

.selectable-header-cell-input {
    vertical-align: middle;
}