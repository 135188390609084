.inline-filter-cell {
    background-color: white;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    color: #015CAB;
}

.filter-text-input {
    font-size: .75rem;
    padding: 0.06rem;
    display: inline-block;
    border: 0;
}

.filter-icon {
    font-size: 12px;
    line-height: 38px;
}

.selected-filter-type {
    background-color: #E7EEF8;
}