.selectable-row-filter-cell {
    background-color: white;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    color: #015CAB;
}
